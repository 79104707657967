<template>
	<div class="confirmation--payment-failed flex flex-col space-y-3 p-4 rounded-2xl">
		<div class="flex justify-center space-x-2 items-center">
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="32" height="32" rx="16" fill="#EB5757"/>
				<path d="M10 22L22 10M10 10L22 22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<h2 class="text-white">{{ $t('order.payment-failed-title') }}</h2>
		</div>

		<p class="text-center px-8 text-white text-opacity-75">{{ $t('order.payment-failed-description') }}</p>

		<div class="flex justify-center">
			<button v-if="!startedInCurrentWindow" class="text-base text-white font-medium py-2 px-6 border-2 border-white rounded-full">
				Try again
			</button>

			<!-- Return to app -->
			<p v-else class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
				{{ $t('order.order-overview.return-to-app') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		startedInCurrentWindow: Boolean,
	}
}
</script>

<style lang="scss" scoped>
.confirmation--payment-failed {
	background: linear-gradient(0deg, rgba(235, 87, 87, 0.16), rgba(235, 87, 87, 0.16)), #2E2E2E;
}
</style>
