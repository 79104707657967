<template>
    <!-- eslint-disable max-len -->
    <div class="WalletBalanceCard px-4 py-5 bg-primary-brand rounded-2xl flex items-center justify-between">
        <div class="JuneaWalletCard_Logo flex items-center text-white">
            <SVGLogo height="28" />
            <h3 class="opacity-50 ml-1 mt-2 font-medium text-white">Wallet</h3>
        </div>

        <div class="JuneaWalletCard_Balance text-right">
            <span class="block text-xs opacity-50 text-white">{{ $t('account.wallet.balance') }}</span>
            <span class="block text-white">{{ currency(balance) }}</span>
        </div>
    </div>
</template>

<script>
import useWallet from '@/composables/useWallet';
import useFilters from '@/filters';
import { onBeforeMount } from '@vue/composition-api';
import SVGLogo from '@/assets/svg/logo.svg?inline';

export default {
    name: 'WalletBalanceCard',

    components: {
        SVGLogo,
    },

    setup() {
        const { balance, getWalletBalance } = useWallet();
        const { currency } = useFilters();

        onBeforeMount(async () => {
            if (!balance.value) {
                await getWalletBalance();
            }
        });

        return {
            balance,
            currency,
        };
    },
};
</script>

<style lang="scss">
.WalletBalanceCard {}
</style>
