<template>
    <!-- eslint-disable max-len -->
    <div @close="$emit('close')" @click="toggleProductDetailDrawer"  class="fridge-FridgeProductItem bg-day-01dp dark:bg-night-04dp rounded-lg py-3 px-4 flex items-center justify-between">
        <div class="flex items-center">
            <div class="bg-white rounded">
                <img :src="product.image" :alt="product.name" class="w-12 h-12 object-contain rounded" />
            </div>

            <div class="ml-2">
                <span class="block font-medium">{{ product.name }}</span>
                <span class="block text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ currency(slotData.price) }}</span>
            </div>
        </div>

        <div @click.stop v-if="allowAdd && cartItem && cartItem.amount > 0" class="OrderCartItem_Right ml-2">
            <AppAmountSelector
                :primary-color="true"
                :amount="cartItem.amount"
                :max="slotData.stock"
                @increase="increaseAmount"
                @decrease="decreaseAmount" />
        </div>

        <button
            v-else
            @click.stop="addProductToCart(slotData.slot, product.id, true), $emit('product-added')"
            class="OrderProductGridItem_AddToCart p-2 rounded-full bg-primary-brand shadow-glow-brand-button"
            :class="{ 'pointer-events-none opacity-50 cursor-not-allowed': cartItem && cartItem.amount >= slotData.stock }">

            <AppIcon icon-name="shopping-cart" class="text-white" />
        </button>

        <!-- <div class="rounded-full bg-primary-brand py-1 px-2 text-white">
            -30%
        </div> -->

        <portal to="drawers">
            <ProductDetailDrawer
                v-if="showProductDetailDrawer"
                :slot-data="slotData"
                :product="product"
                :allow-add="true"
                :amount="cartItem && cartItem.amount ? cartItem.amount : 0"
                @add-to-cart="increaseAmount"
                @close="toggleProductDetailDrawer">

                <template #amount-selector>
                    <AppAmountSelector
                        :primary-color="true"
                        :amount="cartItem && cartItem.amount ? cartItem.amount : 0"
                        :max="slotData.stock"
                        @increase="increaseAmount"
                        @decrease="decreaseAmount" />
                </template>
            </ProductDetailDrawer>
        </portal>
    </div>
</template>

<script>
import AppAmountSelector from '@/components/Interface/AppAmountSelector.vue';
import ProductDetailDrawer from '@/components/Drawers/ProductDetail.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { computed, ref } from '@vue/composition-api';
import useOrder from '@/composables/useOrder';
import useFilters from '@/filters';

export default {
    name: 'FridgeProductItem',

    props: {
        slotData: Object,
        product: Object,
        allowAdd: {
            type: Boolean,
            default: true,
        },
    },

    components: {
        AppAmountSelector,
        ProductDetailDrawer,
        AppIcon,
    },

    setup(props, { root }) {
        const { currency } = useFilters();
        const {
            cart,
            addProductToCart,
            togglePaymentMethodDrawer,
            removeProductFromCart,
        } = useOrder();

        const showProductDetailDrawer = ref(false);

        /**
         * Method to toggle the product detail drawer
         * @returns {void}
         */
        const toggleProductDetailDrawer = () => {
            showProductDetailDrawer.value = !showProductDetailDrawer.value;
        };

        /* eslint-disable arrow-body-style */
        const cartItem = computed(() => {
            return cart.value.find((item) => item.slot === props.slotData.slot);
        });

        /**
         * Method to increase the amount of the current cart item
         * @returns {void}
         */
        const increaseAmount = () => {
            if (!cartItem.value) {
                addProductToCart(props.slotData.slot, props.product.id, true);
                return;
            }

            if (cartItem.value.amount < props.slotData.stock) {
                addProductToCart(props.slotData.slot, props.product.id, true);
            } else {
                root.$toast.open({
                    message: root.$t('order.out-of-stock-notification'),
                    type: 'warning',
                });
            }
        };

        /**
         * Method to increase the amount of the current cart item
         * @returns {void}
         */
        const decreaseAmount = () => {
            // If decrease to 0, remove item from cart
            if (cartItem.value.amount === 1) {
                removeProductFromCart(props.slotData.slot);
                return;
            }

            // If amount is greater than 1, decrease amount
            if (cartItem.value.amount > 1) {
                cartItem.value.amount -= 1;
            }
        };

        return {
            showProductDetailDrawer,
            toggleProductDetailDrawer,
            addProductToCart,
            togglePaymentMethodDrawer,
            currency,
            cartItem,
            increaseAmount,
            decreaseAmount,
        };
    },
};
</script>

<style lang="scss" scoped>
.fridge-FridgeProductItem {}
</style>
