<template>
	<div class="confirmation-slider-item bg-day-04dp dark:bg-night-04dp rounded-2xl">
		<img :src="require(`@/assets/images/confirmation/${image}`)" alt="" class="rounded-t-2xl">

		<div class="px-4 py-6 flex flex-col items-center space-y-4">
			<slot />
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
	props: {
		image: String,
	}
});
</script>

<style lang="scss" scoped>
.confirmation-slider-item {

	img {
		@apply w-full object-cover;
		aspect-ratio: 4 / 3;
		max-height: 500px;
	}
}
</style>
