<template>
    <!-- eslint-disable max-len -->
    <BaseModal @close="$emit('close')" class="InsufficientBalanceModal" :title="$t('tap-and-go.instructions-modal.title')">
        <p v-html="$t('tap-and-go.instructions-modal.content')" class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis"></p>

        <div v-if="!tapAndGoEnabled" class="InsufficientBalanceModal_Actions mt-4">
            <RouterLink :to="{ name: 'NoAccount' }">
                <AppButton v-if="!loggedIn" width="full">{{ $t('tap-and-go.instructions-modal.login-button') }}</AppButton>
            </RouterLink>

            <AppButton v-if="loggedIn" @click="activateTapAndGo" width="full" v-html="$t('tap-and-go.instructions-modal.activate-button')"></AppButton>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/Interface/BaseModal.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useTapAndgo from '@/composables/useTapAndGo';
import useAuth from '@/composables/useAuth';

export default {
    name: 'InsufficientBalanceModal',

    components: {
        BaseModal,
        AppButton,
    },

    setup(_, { emit }) {
        const { enabled: tapAndGoEnabled, toggleTapAndGo } = useTapAndgo();
        const { loggedIn } = useAuth();

        const activateTapAndGo = () => {
            toggleTapAndGo();
            emit('close');
        };

        return {
            tapAndGoEnabled,
            loggedIn,
            activateTapAndGo,
        };
    },
};
</script>

<style lang="scss">
.InsufficientBalanceModal {}
</style>
