var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"OrderProductGridItem bg-day-01dp dark:bg-night-16dp rounded-lg p-2 flex flex-col relative cursor-pointer shadow-grid",class:{
            'opacity-50 cursor-not-allowed': _vm.orderStatus === 'processing' && _vm.orderProduct.product !== _vm.product.id
                || _vm.tapAndGoEnabled && _vm.balance < (_vm.slotData.price + (_vm.slotData.deposit || 0))
                || _vm.machineStatus === 'unavailable'
                || _vm.machineStatus === 'busy'
                || _vm.showUnavailableMessage
        },on:{"click":_vm.toggleProductDetailDrawer}},[_c('div',{staticClass:"OrderProductGridItem_Image bg-white rounded py-4"},[_c('img',{staticClass:"absolute inset-0 mx-auto rounded h-full object-cover p-2",attrs:{"src":_vm.product.image,"alt":_vm.product.name}})]),_c('div',{staticClass:"OrderProductGridItem_ProductInfo mt-2"},[_c('span',{staticClass:"block font-medium"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"inline-flex space-x-1"},[(_vm.tapAndGoEnabled && !_vm.machine.has_free_vending)?_c('span',{staticClass:"block font-bold text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis"},[_vm._v(" "+_vm._s(_vm.currency(_vm.slotData.price))+" ")]):_c('span',{staticClass:"block font-bold text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis"},[_vm._v(" "+_vm._s(_vm.slotData.price == 0 ? _vm.$t('order.free') : _vm.currency(_vm.slotData.price))+" ")]),(_vm.slotData.deposit && _vm.slotData.deposit > 0)?_c('span',{staticClass:"text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled"},[_vm._v(" "+_vm._s(_vm.$t('order.excl-deposit'))+" ")]):_vm._e()])]),(_vm.tapAndGoEnabled && _vm.balance > (_vm.slotData.price + (_vm.slotData.deposit || 0)) || _vm.tapAndGoEnabled && _vm.machine.has_free_vending )?_c('button',{staticClass:"OrderProductGridItem_TapAndGo absolute p-2 rounded-full bg-primary-brand shadow-glow-brand-button",attrs:{"disabled":_vm.orderStatus === 'processing' || _vm.machineStatus === 'unavailable' || _vm.machineStatus === 'busy' || _vm.showUnavailableMessage},on:{"click":function($event){$event.stopPropagation();return _vm.orderTappedProduct({
                slot: _vm.slotData.slot,
                product: _vm.product.id,
                amount: 1,
            })}}},[(_vm.orderProduct && _vm.orderProduct.product === _vm.product.id && _vm.orderStatus === 'processing')?_c('AppIcon',{key:"tap-and-go-order-processing",staticClass:"text-white",attrs:{"icon-name":"processing","spin":true}}):(_vm.orderProduct && _vm.orderProduct.product === _vm.product.id && _vm.orderStatus === 'success')?_c('AppIcon',{key:"tap-and-go-order-success",staticClass:"text-white",attrs:{"icon-name":"check"}}):_c('AppIcon',{key:"tap-and-go-order",staticClass:"text-white",attrs:{"icon-name":"fast-buy"}})],1):_vm._e(),(!_vm.tapAndGoEnabled)?_c('button',{staticClass:"OrderProductGridItem_AddToCart absolute p-2 rounded-full bg-primary-brand shadow-glow-brand-button",class:{ 'opacity-50 cursor-not-allowed': _vm.hasReachedMax },attrs:{"disabled":_vm.hasReachedMax || _vm.machineStatus === 'unavailable' || _vm.machineStatus === 'busy' || _vm.showUnavailableMessage},on:{"click":function($event){$event.stopPropagation();return _vm.addProductToCart(_vm.slotData.slot, _vm.product.id)}}},[_c('AppIcon',{key:"default-order",staticClass:"text-white",attrs:{"icon-name":"plus"}})],1):_vm._e(),_c('portal',{attrs:{"to":"drawers"}},[(_vm.showProductDetailDrawer)?_c('ProductDetailDrawer',{attrs:{"slot-data":_vm.slotData,"product":_vm.product,"allow-add":!_vm.hasReachedMax},on:{"add-to-cart":function($event){return _vm.addProductToCart(_vm.slotData.slot, _vm.product.id)},"close":_vm.toggleProductDetailDrawer}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }