<template>
    <!-- eslint-disable max-len -->
    <div v-if="!refillReference || refillReference && filteredSlots.length > 0" class="OperatorDrinksShelve bg-day-04dp dark:bg-night-08dp p-4 rounded-lg">
        <header class="flex items-center justify-between">
            <div @click="updateSelectedShelves" class="flex space-x-3 items-center cursor-pointer flex-grow">
                <AppIcon v-if="modelValue.includes(index)" key="close" icon-name="minus" :width="20" :height="20" />
                <AppIcon v-if="!modelValue.includes(index)" key="open" icon-name="plus" :width="20" :height="20" />

                <span>{{ $t('operator.shelf') }} {{ shelve.position + 1 }}</span>
            </div>

            <div class="flex space-x-3 items-center">
                <span v-if="!refillReference" @click="selectAllSlots" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis underline cursor-pointer">{{ hasAllSlotsSelected ? 'Alles deselecteren' : $t('drinks.select-all') }}</span>
                <span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ selectedSlots.length }} / {{ shelve.slots.length }}</span>
            </div>
        </header>

        <div v-if="modelValue.includes(index)" class="grid gap-2 mt-4">
            <DrinksSlot
                v-for="slot in filteredSlots"
                :key="`${slot.id}-${refillIteration}`"
                v-model="selectedSlots"
                :slot-data="slot"
                :allow-refill="!!refillReference"
                @refill-slot="toggleRefillDrawer"
                :loading="refillReference === 'loading'"
                :completed="slot.completedRefill" />
        </div>

        <button v-if="modelValue.includes(index) && !isLastShelve && !refillReference" @click="$emit('open-next-shelve', index)" class="flex w-full justify-center border-1-5 border-night-01dp dark:border-white text-night-01dp dark:text-white py-3 px-4 rounded-full mt-8 font-medium">
            {{ $t('drinks.next-drawer') }}
        </button>

        <portal to="drawers" :order="3">
            <RefillDrawer
                :key="refillIteration"
                v-if="refillDrawerActive"
                :refill-slot-data="refillSlotData"
                :reference="refillReference"
                @close="toggleRefillDrawer"
                @refilled="setUpdatedSlotStock"/>
        </portal>
    </div>
</template>

<script>
import DrinksSlot from '@/components/Operator/Drinks/Slot.vue';
import RefillDrawer from '@/components/Operator/RefillDrawer.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { ref, set } from '@vue/composition-api';

export default {
    name: 'DrinksShelve',

    components: {
        DrinksSlot,
        RefillDrawer,
        AppIcon,
    },

    props: {
        shelve: Object,
        refillReference: String,
        modelValue: { default: '' },
        index: Number,
        isLastShelve: Boolean,
    },

    model: {
        prop: 'modelValue',
        event: 'change',
    },

    data() {
        return {
            selectedSlots: [],
        };
    },

    watch: {
        selectedSlots(newValue) {
            this.$emit('update-selected-slots', {
                shelve: this.shelve.position,
                slots: newValue,
            });
        },
    },

    methods: {
        selectAllSlots() {
            this.selectedSlots = this.shelve.slots.map((slot) => slot.id);
        },

        deselectAllSlots() {
            this.selectedSlots = [];
        },

        updateSelectedShelves() {
            const newValue = [...this.modelValue];

            if (!newValue.includes(this.shelve.position)) {
                newValue.push(this.shelve.position);
            } else {
                newValue.splice(newValue.indexOf(this.shelve.position), 1);
            }

            this.$emit('change', newValue);
        },
    },

    computed: {
        filteredSlots() {
            if (this.refillReference) {
                return this.shelveSlots.filter((slot) => this.selectedSlots.includes(slot.id));
            }

            return this.shelveSlots;
        },

        hasAllSlotsSelected() {
            const arr1 = this.selectedSlot || [];
            const arr2 = this.shelve.slots.map((slot) => slot.id) || [];

            const set1 = new Set(arr1);
            const set2 = new Set(arr2);

            return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
        },
    },

    setup(props) {
        const refillDrawerActive = ref(false);
        const refillSlotData = ref(null);

        const toggleRefillDrawer = (slotData) => {
            if (props.refillReference) {
                refillDrawerActive.value = !refillDrawerActive.value;
                refillSlotData.value = slotData;
            }
        };

        const shelveSlots = ref(props.shelve.slots);
        const refillIteration = ref(0);

        const setUpdatedSlotStock = (updatedSlotData) => {
            const index = shelveSlots.value.findIndex((slot) => slot.id === updatedSlotData.id);

            set(shelveSlots.value, index, updatedSlotData);
            set(shelveSlots.value[index], 'completedRefill', true);

            refillIteration.value += 1;

            toggleRefillDrawer();
        };

        return {
            refillDrawerActive,
            toggleRefillDrawer,
            refillSlotData,
            shelveSlots,
            setUpdatedSlotStock,
            refillIteration,
        };
    },
};
</script>
