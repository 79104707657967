<template>
	<div @click="$emit('close')" class="confirmation-take-out-video fixed inset-0 w-full h-full bg-black bg-opacity-50 flex items-center z-30">
		<div @click.stop class="bg-day-01dp dark:bg-night-04dp rounded-2xl mx-5 delay-100 w-full lg:max-w-2xl lg:mx-auto">
			<video
				autoplay="autoplay"
				muted
				playsinline
				class="rounded-t-2xl w-full">
				<source v-if="type === 'drinks'" src="@/assets/videos/drinks-confirmation.mp4" type="video/mp4" />
				<source v-else src="@/assets/videos/snacks-confirmation.mp4" type="video/mp4" />
			</video>

			<div class="px-4 py-6 flex flex-col items-center space-y-4">
				<div class="text-center">
					<h2>{{ $t('confirmation.video-overlay.title') }}</h2>

					<p v-if="type === 'drinks'" class="dark:text-gray-300">
						{{ $t('confirmation.video-overlay.description-drinks') }}
					</p>

					<p v-else class="dark:text-gray-300">
						{{ $t('confirmation.video-overlay.description-snacks') }}
					</p>
				</div>

				<div>
					<AppButton @click="$emit('close')" type="primary" size="small">{{ $t('confirmation.video-overlay.continue') }} ({{ timerCount }})</AppButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import AppButton from '@/components/Interface/AppButton.vue';

export default defineComponent({
	props: {
		type: String,
	},

	components: {
		AppButton,
	},

	data() {
		return {
			timerCount: 15,
		}
	},

	watch: {
		timerCount: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.timerCount--;
					}, 1000);
				} else {
					this.$emit('close');
				}
			},
			immediate: true // This ensures the watcher is triggered upon creation
		}
	}
});
</script>

<style lang="scss" scoped>
.confirmation-take-out-video {
	backdrop-filter: blur(2px);
}
</style>
