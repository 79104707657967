<template>
    <!-- eslint-disable max-len -->
    <BaseDialog @close="$emit('close')" class="NoConnectionDialog z-50">
        <div class="NoConnectionDialog_Message text-center">
            <h2>{{ $t('connection-error.heading') }}</h2>
            <p class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('connection-error.description') }}</p>
        </div>

        <div class="DisconnectDialog_Actions mt-6 flex justify-center">
            <AppButton @click="disconnect" icon="disconnect" size="small" type="danger">{{ $t('connection-error.order-disconnect-button') }}</AppButton>
        </div>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import router from '@/router';

export default {
    name: 'NoConnectionDialog',

    components: {
        BaseDialog,
        AppButton,
    },

    setup() {
        const { disconnectMachine } = useMachine();
        const { resetOrderState } = useOrder();

        /**
         * Method to disconnect from machine and navigate back to home
         * @returns {void}
         */
        const disconnect = () => {
            disconnectMachine();
            resetOrderState();

            router.push({ name: 'HomeScreen' });
        };

        return {
            disconnect,
        };
    },
};
</script>

<style lang="scss">
.NoConnectionDialog {}
</style>
