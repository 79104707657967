<template>
    <!-- eslint-disable max-len -->
    <label @click.stop class="ui-AppSwitch relative inline-block w-12 h-6">
        <input type="checkbox" v-model="checkValue" class="w-0 h-0 opacity-0" :disabled="disabled">
        <span class="ui-AppSwitch_Slider absolute cursor-pointer inset-0 transition duration-300 rounded-full"></span>
    </label>
</template>

<script>
export default {
    name: 'UIAppSwitch',
    props: ['value', 'disabled'],

    computed: {
        checkValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-AppSwitch {

    &_Slider {
        background-color: rgba(0, 0, 0, 0.2);

        &::before {
            position: absolute;
            content: '';
            width: theme('width.5');
            height: theme('width.4');
            left: 4px;
            top: 4px;
            background-color: theme('colors.white');
            transition: 400ms all ease;
            border-radius: theme('borderRadius.full');
        }
    }

    input:checked {
        &+ .ui-AppSwitch_Slider {
            background-color: theme('colors.primary.brand');
            box-shadow: theme('boxShadow.glow-brand-button');

            &::before {
                background-color: theme('colors.white');
                transform: translateX(20px);
            }
        }

    }
}

.mode-dark {

    .ui-AppSwitch {

        &_Slider {
            background-color: theme('colors.day.on-surface');

            &::before  {
                background-color: theme('colors.night-on-surface.low-emphasis-disabled');
            }
        }
    }
}
</style>
