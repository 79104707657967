<template>
    <!-- eslint-disable max-len -->
    <transition name="dialog" appear>
        <div @click="$emit('close')" class="ui-BaseModal fixed inset-0 z-30 flex justify-center items-center">
            <div @click.stop class="ui-BaseModal_Inner p-6 mx-4 rounded-3xl bg-white dark:bg-night-04dp w-full relative">
                <header class="flex items-center justify-between mb-2">
                    <h2 v-html="title"></h2>
                    <AppIcon @click="$emit('close')" icon-name="exit" :width="24" :height="24" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" />
                </header>

                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
    name: 'UIBaseModal',

    props: {
        title: String,
    },

    components: {
        AppIcon,
    },
};
</script>

<style lang="scss">
.ui-BaseModal {
    background: rgba(0, 0, 0, 0.6);

    // &_Close {
    //     top: 24px;
    //     right: 24px;
    // }
}

.dialog-enter-active,
.dialog-leave-active {
    transition: all 200ms linear;
    background: rgba(0, 0, 0, 0.6);

    .ui-BaseModal_Inner {
        transition: all 200ms linear;
    }
}

.dialog-enter,
.dialog-leave-to {
    background: rgba(0, 0, 0, 0.0);

    .ui-BaseModal_Inner {
        opacity: 0;
    }
}
</style>
