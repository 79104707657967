<template>
  <!-- eslint-disable max-len -->
  <BaseDrawer @close="$emit('close')" class="fridge-ProductDrawer">
    <div class="grid gap-2">
      <!-- <AppInputField v-model="searchTerm" :placeholder="$t('fridge.search-label')" /> -->

      <div class="flex flex-no-wrap overflow-auto space-x-2 pb-4">
        <div
          @click="selectedCategory = 'all'"
          class="rounded-full py-1 px-4"
          :class="{
            'bg-primary-brand text-white': selectedCategory === 'all',
            'bg-day-01dp dark:bg-night-04dp': selectedCategory !== 'all',
          }"
          style="min-width: fit-content"
        >
          {{ $t("fridge.all-products") }}
        </div>

        <div
          v-for="category in productCategories"
          :key="`category-${category.reference}`"
          @click="selectedCategory = category.reference"
          class="rounded-full py-1 px-4"
          style="min-width: fit-content"
          :class="{
            'bg-primary-brand text-white': selectedCategory === category.reference,
            'bg-day-01dp dark:bg-night-04dp': selectedCategory !== category.reference,
          }"
        >
          {{ category.name }}
        </div>
      </div>

      <FridgeProductItem
        v-for="slot in categoryFilteredProducts"
        :key="slot.id"
        :slot-data="slot"
        :product="slot.product"
        :allow-add="allowAddToCart"
        @product-added="$emit('close')"
      />
    </div>
  </BaseDrawer>
</template>

<script>
/* eslint-disable max-len */
import BaseDrawer from "@/components/Interface/BaseDrawer.vue";
// import AppInputField from '@/components/Interface/AppInputField.vue';
import FridgeProductItem from "@/components/Fridge/FridgeProductItem.vue";
import { computed, ref } from "@vue/composition-api";

export default {
  name: "ProductDrawer",

  components: {
    BaseDrawer,
    // AppInputField,
    FridgeProductItem,
  },

  props: {
    uniqueSlots: Array,
    allowAddToCart: Boolean,
  },

  setup(props) {
    // const searchTerm = ref('');

    // const searchFilteredUniqueSlots = computed(() => {
    //     if (searchTerm.value.length > 0) {
    //         /* eslint-disable arrow-body-style */
    //         return props.uniqueSlots.filter((slot) => {
    //             return slot.product.name.toLowerCase().includes(searchTerm.value.toLowerCase());
    //         });
    //     }

    //     return props.uniqueSlots;
    // });

    // Map all categories from slot product into new array
    let productCategories = props.uniqueSlots
      .filter((slot) => slot.product.category)
      .map((slot) => slot.product.category);

    // Filter all unique categories
    productCategories = [
      ...new Map(productCategories.map((item) => [item.reference, item])).values(),
    ];

    // Current selected category
    const selectedCategory = ref("all");

    // Filter products based on selected category
    const categoryFilteredProducts = computed(() => {
      // If category all is selected, return all slots
      if (selectedCategory.value === "all") {
        return props.uniqueSlots;
      }

      return props.uniqueSlots.filter(
        (slot) => slot.product.category?.reference === selectedCategory.value
      );
    });

    return {
      // searchTerm,
      // searchFilteredUniqueSlots,
      productCategories,
      selectedCategory,
      categoryFilteredProducts,
    };
  },
};
</script>
