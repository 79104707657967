<template>
    <!-- eslint-disable max-len -->
    <div class="OperateDrinksRefill h-full flex flex-col pb-24">
        <TheOrderHeader />

        <main v-if="!finalizingRefill" class="machine-flows-junea-OrderJunea_Content flex-grow -mt-5 rounded-t-3xl p-5 h-full overflow-y-auto">
            <header class="flex items-center justify-between mb-4">
                <h2>{{ refillReference ? $t('drinks.start-refilling') : $t('drinks.select-slots-to-refill') }}</h2>
                <span v-if="!refillReference" @click="selectAllSlots" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis underline cursor-pointer text-right flex-shrink-0">{{ $t('drinks.select-all') }}</span>
            </header>

            <div v-if="machineOperatorData && machineOperatorData.shelves" class="grid gap-4">
                <DrinksShelve
                    v-for="(shelve, index) in machineOperatorData.shelves"
                    :key="`${shelve.position}-${index}-${refillIteration}`"
                    :shelve="shelve"
                    :index="index"
                    :is-last-shelve="index === machineOperatorData.shelves.length - 1"
                    v-model="openShelves"
                    :refill-reference="refillReference"
                    @update-selected-slots="updateAllSelectedSlots"
                    @open-next-shelve="openNextShelve"
                    :ref="`shelve-${shelve.position}`" />
            </div>
        </main>

        <div v-if="finalizingRefill" class="text-center flex flex-col justify-center items-center space-y-2 flex-grow">
            <svg v-if="machineStatus !== 'finished'" class="spinner mr-3 flex-shrink-0" viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
            </svg>

            <h1>{{ machineStatus === 'finished' ? $t('drinks.success') : $t('drinks.please-wait') }}</h1>
            <p v-if="machineStatus !== 'finished'" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('drinks.motors-returning') }}</p>
        </div>

        <div class="OperateDrinksRefill_Actions">
            <AppButton v-if="!refillReference && !finalizingRefill && allSelectedSlots.length > 0" @click="initiateNewRefill" size="small" width="full">{{ $t('drinks.start-refill') }} — {{ allSelectedSlots.length }} Slots</AppButton>
            <AppButton v-if="refillReference && !finalizingRefill" @click="finishRefill" size="small" width="full">{{ $t('drinks.complete-refill') }}</AppButton>

            <RouterLink v-if="finalizingRefill" :to="{ name: 'OrderJunea' }">
                <AppButton size="small" width="full">{{ $t('drinks.done') }}</AppButton>
            </RouterLink>
        </div>

        <TheBottomMenu />
    </div>
</template>

<script>
/* eslint-disable max-len, object-curly-newline */
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import DrinksShelve from '@/components/Operator/Drinks/Shelve.vue';
import useMachine from '@/composables/useMachine';
import AppButton from '@/components/Interface/AppButton.vue';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, set } from '@vue/composition-api';
import Pusher from 'pusher-js';

export default {
    name: 'OperatingDrinks',

    components: {
        TheOrderHeader,
        TheBottomMenu,
        DrinksShelve,
        AppButton,
    },

    setup(_, { root, emit, refs }) {
        const { fetchMachineData, code, machineOperatorData, machine } = useMachine();
        const refillReference = ref(null);
        const initializingRefill = ref(false);
        const finalizingRefill = ref(false);
        const machineStatus = ref(null);
        const openShelves = ref([0]);
        const refillIteration = ref(0);

        const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
            cluster: 'eu',
        });

        onMounted(() => {
            const channel = pusher.subscribe(machine.value.pusher_channel);

            channel.bind('machine.drinks-refill', (event) => {
                if (event.status === 'done') {
                    machineStatus.value = 'finished';
                }
            });
        });

        const fetchMachineOperatorData = async () => {
            try {
                const response = await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}`);
                machineOperatorData.value = response.data.data;
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        onBeforeMount(async () => {
            await fetchMachineOperatorData();
        });

        const allSelectedSlotsByShelve = ref({});

        const allSelectedSlots = computed(() => {
            const uniqueSlotsIds = [].concat(...Object.values(allSelectedSlotsByShelve.value));
            return uniqueSlotsIds.map((slot) => ({ slot_id: slot }));
        });

        function updateAllSelectedSlots(event) {
            set(allSelectedSlotsByShelve.value, event.shelve, event.slots);

            const shelveComponent = refs[`shelve-${event.shelve}`][0];
            shelveComponent.selectedSlots = event.slots;
        }

        function selectAllSlots() {
            this.machineOperatorData.shelves.forEach((shelve) => {
                updateAllSelectedSlots({
                    shelve: shelve.position,
                    slots: shelve.slots.map((slot) => slot.id),
                });
            });
        }

        function openNextShelve(currentShelve) {
            openShelves.value = [];
            openShelves.value.push(currentShelve + 1);
        }

        function openAllShelves() {
            openShelves.value = [];

            for (let index = 0; index < machineOperatorData.value.shelves.length; index += 1) {
                openShelves.value.push(index);
            }
        }

        const initiateNewRefill = async () => {
            initializingRefill.value = true;

            try {
                refillReference.value = 'loading';
                openAllShelves();

                const response = await root.$http.post(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/init-refill`);

                const initHomingResponse = await root.$http.post(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/init-homing`, {
                    refill_identifier: response.data.data.reference,
                    slots: allSelectedSlots.value,
                });

                if (initHomingResponse.data.message === 'homing-initiated') {
                    refillReference.value = response.data.data.reference;
                    initializingRefill.value = false;

                    root.$toast.open({
                        message: root.$t('operator.notifications.door-unlocked'),
                        type: 'success',
                    });
                }
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        const finishRefill = async () => {
            finalizingRefill.value = true;

            try {
                const requestUrl = `${process.env.VUE_APP_OPERATOR_API_URL}/refill/${refillReference.value}`;

                await root.$http.put(requestUrl, {
                    machine: code.value,
                    slots: allSelectedSlots.value,
                });

                root.$toast.open({
                    message: root.$t('operator.notifications.saved'),
                    type: 'success',
                });

                refillReference.value = null;
                openShelves.value = [0];
                allSelectedSlotsByShelve.value = {};
                refillIteration.value += 1;
                machineOperatorData.value = null;

                // Refetch machine data for new stock values
                fetchMachineData(code.value);

                emit('close');
            } catch (error) {
                console.log(error);
            }
        };

        onBeforeUnmount(() => {
            pusher.disconnect();
        });

        return {
            machineOperatorData,
            refillReference,
            initiateNewRefill,
            initializingRefill,
            finishRefill,
            allSelectedSlots,
            updateAllSelectedSlots,
            selectAllSlots,
            openShelves,
            openNextShelve,
            finalizingRefill,
            machineStatus,
            refillIteration,
        };
    },
};
</script>

<style lang="scss" scoped>
.OperateDrinksRefill {
    &_Actions {
        position: sticky;
        width: calc(100% - 40px);
        bottom: 80px;
        z-index: 10;
        left: 20px;
    }

    .spinner circle {
        fill: none;
        stroke: #1a1a1a;
        stroke-width: 16;
        stroke-linecap: round;
        stroke-dasharray: 0, 0, 70, 194;
        stroke-dashoffset: 0;
        animation: spin 1.4s infinite linear;
        -webkit-animation: spin 1.4s infinite linear;
    }
}

.mode-dark .OperateDrinksRefill {

    .spinner circle {
        stroke: white;
    }
}
</style>
