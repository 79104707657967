<template>
	<!-- eslint-disable max-len -->
	<div class="machine-flows-ProcessingPayment flex flex-col overflow-y-auto bg-day-01dp dark:bg-night-01dp">
		<TheDefaultHeader />

		<main class="machine-flows-ProcessingPayment_Content flex-grow flex flex-col flex-shrink-0 justify-center px-4 space-y-4">
			<ConfirmationPaymentPending v-if="fetchingPaymentStatus" />

			<ConfirmationPaymentSuccess
				v-if="!fetchingPaymentStatus && !paymentError"
				:started-in-current-window="false" />

			<ConfirmationPaymentFailed v-if="paymentError && machine" />

			<ConfirmationSlider />
		</main>

		<!-- <TheBottomMenu v-if="paymentStartedInCurrentWindow" /> -->
		<TheBottomMenu />

		<Transition name="video-overlay" appear>
			<ConfirmationTakeOutVideoOverlay
				v-if="showVideoOverlay && machine && !fetchingPaymentStatus && !paymentError"
				:type="machine.type"
				@close="showVideoOverlay = false" />
		</Transition>
	</div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import ConfirmationPaymentSuccess from '@/components/Confirmation/PaymentSuccess.vue';
import ConfirmationPaymentPending from '@/components/Confirmation/PaymentPending.vue';
import ConfirmationPaymentFailed from '@/components/Confirmation/PaymentFailed.vue';
import ConfirmationSlider from '@/components/Confirmation/Slider.vue';
import ConfirmationTakeOutVideoOverlay from '@/components/Confirmation/TakeOutVideoOverlay.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import AddToHomescreenNotfication from '@/components/AddToHomescreenNotfication.vue';
import { onMounted, ref } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';

export default {
	name: 'OrderProcessingPayment',

	components: {
		TheDefaultHeader,
		ConfirmationPaymentSuccess,
		ConfirmationPaymentPending,
		ConfirmationPaymentFailed,
		ConfirmationSlider,
		TheBottomMenu,
		AddToHomescreenNotfication,
		ConfirmationTakeOutVideoOverlay,
	},

	setup(_, { root }) {
		const { machine } = useMachine();
		const { fridgeSessionId } = useOrder();

		const fetchingPaymentStatus = ref(false);
		const paymentError = ref(null);

		async function checkPaymentStatus(paymentOrderReference) {
			fetchingPaymentStatus.value = true;

			try {
				const paymentStatusResponse = await root.$http.get(`/payment-status/${paymentOrderReference}`);
				const paymentStatus = paymentStatusResponse.data.payment_status;

				// Payment paid: show success message
				if (paymentStatus === 'paid') {
					fetchingPaymentStatus.value = false;

					// Remove cart from local storage after successful payment
					localStorage.removeItem('order-cart');
				}

				// Payment canceled: show error
				if (paymentStatus === 'canceled') {
					paymentError.value = 'payment_canceled';

					fetchingPaymentStatus.value = false;
					// localStorage.removeItem('order-id');
				}

				// Still pending, try again in 5 seconds
				if (paymentStatus === 'init') {
					throw new Error('payment_pending');
				}
			} catch (error) {
				setTimeout(() => {
					checkPaymentStatus(paymentOrderReference);
				}, 5000);
			}
		}

		const paymentStartedInCurrentWindow = ref(false);

		onMounted(() => {
			const { reference, type } = root.$router.currentRoute.query;

			if (localStorage.getItem('processing-payment')) {
				paymentStartedInCurrentWindow.value = true;

				localStorage.removeItem('processing-payment');
			}

			if (reference && type !== 'wallet') {
				checkPaymentStatus(reference);
			}
		});

		async function startNewFridgeSession() {
			try {
				const response = await root.$http.post('/fridge/session', {
					machine_number: machine.value.code,
				});

				fridgeSessionId.value = response.data.session_id;

				root.$router.push({ name: 'OrderFridgeProducts' });
			} catch (error) {
				// Check if machine is in use
				if (error.response.data.error_type && error.response.data.error_type === 'machine-in-use') {
					root.$toast.open({
						message: root.$t('fridge.machine-in-use'),
						type: 'warning',
						duration: 8000,
					});
				}
			}
		}

		const showVideoOverlay = ref(true)

		return {
			fetchingPaymentStatus,
			paymentError,
			paymentStartedInCurrentWindow,
			machine,
			startNewFridgeSession,
			showVideoOverlay,
		};
	},
};
</script>

<style lang="scss">
.machine-flows-ProcessingPayment {
	height: 100%;
	overflow: auto;
	max-height: calc(100vh);

	&_Content {
		padding-bottom: 84px; // Dock menu + toolbar
	}
}

.video-overlay-enter-active,
.video-overlay-leave-active {
	transition: all 200ms linear;
	background: rgba(0, 0, 0, 0.5);

	> div {
		transition: transform 200ms linear;
		transform: translateY(0%);
	}
}

.video-overlay-enter,
.video-overlay-leave-to {
	background: rgba(0, 0, 0, 0.0);

	> div {
		transition-delay: 2s;
		transform: translateY(10%);
	}
}
</style>
