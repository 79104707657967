<template>
    <!-- eslint-disable max-len -->
    <BaseModal @close="$emit('close')" class="InsufficientBalanceModal" :title="$t('tap-and-go.insufficient-balance-modal.title')">
        <div class="InsufficientBalanceModal_Notice mb-2 px-4 py-2 rounded-lg">
            <p v-html="$t('tap-and-go.insufficient-balance-modal.alert')"></p>
        </div>

        <p v-html="$t('tap-and-go.insufficient-balance-modal.content')" class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis mb-4">Voeg snel saldo toe aan je Wallet of schakel Tap&amp;Go uit om met iDeal af te rekenen.</p>

        <RouterLink :to="{ name: 'Account' }">
            <AppButton @click="$emit('close')" width="full">{{ $t('tap-and-go.insufficient-balance-modal.top-up-button') }}</AppButton>
        </RouterLink>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/Interface/BaseModal.vue';
import AppButton from '@/components/Interface/AppButton.vue';

export default {
    name: 'InsufficientBalanceModal',

    components: {
        BaseModal,
        AppButton,
    },
};
</script>

<style lang="scss">
.InsufficientBalanceModal {

    &_Notice {
        background: rgba(237, 74, 104, 0.24);
        border: 1.5px solid theme('colors.primary.brand');
    }
}
</style>
