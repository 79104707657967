<template>
    <!-- eslint-disable max-len -->
    <div @click="!loggedIn ? toggleInstructionsModal() : null" class="TapAndGoSwitch flex items-center">
        <label class="flex items-center" :class="{ 'opacity-50 pointer-events-none': !loggedIn }" >
            <AppSwitch :value="tapAndGoEnabled" @input="toggleTapAndGo($event)" />
            <span class="font-medium text-base mx-1">Tap&amp;Go</span>
        </label>

        <AppIcon @click.stop="toggleInstructionsModal" icon-name="info" :width="16" :height="16" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" />

         <portal to="drawers">
            <InstructionsModal
                v-if="showInstructionsModal"
                @close="toggleInstructionsModal" />

            <InsufficientBalanceModal
                v-if="showInsufficientBalanceModal"
                @close="toggleInsufficientBalanceModal" />
        </portal>
    </div>
</template>

<script>
import InstructionsModal from '@/components/TapAndGo/InstructionsModal.vue';
import InsufficientBalanceModal from '@/components/TapAndGo/InsufficientBalanceModal.vue';
import AppSwitch from '@/components/Interface/AppSwitch.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useTapAndgo from '@/composables/useTapAndGo';
import useAuth from '@/composables/useAuth';
import { ref } from '@vue/composition-api';

export default {
    name: 'TapAndGoSwitch',

    components: {
        InstructionsModal,
        InsufficientBalanceModal,
        AppSwitch,
        AppIcon,
    },

    setup() {
        const {
            enabled: tapAndGoEnabled,
            toggleTapAndGo,
            showInsufficientBalanceModal,
            toggleInsufficientBalanceModal,
        } = useTapAndgo();

        const { loggedIn } = useAuth();

        const showInstructionsModal = ref(false);

        const toggleInstructionsModal = () => {
            showInstructionsModal.value = !showInstructionsModal.value;
        };

        return {
            tapAndGoEnabled,
            toggleTapAndGo,
            showInstructionsModal,
            toggleInstructionsModal,
            showInsufficientBalanceModal,
            toggleInsufficientBalanceModal,
            loggedIn,
        };
    },
};
</script>

<style lang="scss">
.mode-dark .TapAndGoSwitch {

    .ui-AppSwitch_Slider {
        background-color: theme('colors.night-on-surface.low-emphasis-disabled');
    }
}
</style>
