<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="OrderProductFilterDrawer">
        <header class="OrderProductFilterDrawer_Header flex justify-between">
            <!-- <button class="layout-TheOrderHeader_Exit p-2 rounded-full bg-day-on-surface dark:bg-night-on-surface">
                <AppIcon icon-name="exit" :width="24" :height="24" color="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" />
            </button> -->

            <h1 class="flex-1 text-center">Filters</h1>

            <!-- <button class="layout-TheOrderHeader_Exit p-2 rounded-full bg-day-on-surface dark:bg-night-on-surface">
                <AppIcon icon-name="exit" :width="24" :height="24" color="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" />
            </button> -->
        </header>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
// import AppIcon from '@/components/Interface/AppIcon.vue';
// import AppBadge from '@/components/Interface/AppBadge.vue';

export default {
    name: 'OrderProductFilterDrawer',

    components: {
        BaseDrawer,
        // AppIcon,
        // AppBadge,
    },
};
</script>

<style lang="scss" scoped>
.OrderProductFilterDrawer {}
</style>
