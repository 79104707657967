<template>
	<div class="confirmation-slider flex-grow">
		<Slider ref="confirmation-slider" :options="machineSliderOptions">
			<SliderItem v-if="machine.type === 'drinks'" key="drinks">
				<ConfirmationSliderItem image="drinks.jpg">
					<div class="text-center">
						<h2>{{ $t('confirmation.slider.drinks.title') }}</h2>
					</div>

					<a href="https://junea.nl/?utm_source=checkout&utm_medium=app&utm_campaign=junea-drinks" target="_blank">
						<AppButton type="primary" size="small">{{ $t('confirmation.slider.drinks.button') }}</AppButton>
					</a>
				</ConfirmationSliderItem>
			</SliderItem>

			<SliderItem v-else key="snacks">
				<ConfirmationSliderItem image="snacks.jpg">
					<div class="text-center">
						<h2>{{ $t('confirmation.slider.snacks.title') }}</h2>
					</div>

					<a href="https://junea.nl/?utm_source=checkout&utm_medium=app&utm_campaign=junea-snacks" target="_blank">
						<AppButton type="primary" size="small">{{ $t('confirmation.more-information') }}</AppButton>
					</a>
				</ConfirmationSliderItem>
			</SliderItem>

			<SliderItem key="wallet">
				<ConfirmationSliderItem image="wallet.jpg">
					<div class="text-center">
						<h2>{{ $t('confirmation.slider.wallet.title') }}</h2>
					</div>

					<RouterLink :to="{ name: 'Account' }">
						<AppButton type="primary" size="small">{{ $t('confirmation.slider.wallet.button') }}</AppButton>
					</RouterLink>
				</ConfirmationSliderItem>
			</SliderItem>

			<SliderItem key="homescreen">
				<ConfirmationSliderItem image="homescreen.jpg">
					<div class="text-center">
						<h2>{{ $t('confirmation.slider.homescreen.title') }}</h2>
					</div>
				</ConfirmationSliderItem>
			</SliderItem>
		</Slider>
	</div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import ConfirmationSliderItem from '@/components/Confirmation/SliderItem.vue';
import { slider, slideritem } from 'vue-concise-slider';
import AppButton from '@/components/Interface/AppButton.vue';
import useMachine from '@/composables/useMachine';

export default defineComponent({
	components: {
		ConfirmationSliderItem,
		Slider: slider,
		SliderItem: slideritem,
		AppButton,
	},

	data() {
		return {
			machineSliderOptions: {
				pagination: true,
				grabCursor: true,
			}
		}
	},

	setup() {
		const machine = useMachine();

		const machineSliderOptions = ref({
				pagination: true,
				grabCursor: true,
		})

		return {
			machineSliderOptions,
			machine,
		};
	}
});
</script>

<style lang="scss">
.confirmation-slider {

	.slider-container {
		height: auto;
		padding-bottom: 2rem;

		.slider-item {
			font-size: inherit;
			text-align: left;
			color: inherit;
			margin-right: 2rem;

			h2 {
				white-space: initial;
			}
		}

		.slider-pagination {
				bottom: 0rem;

			.slider-pagination-bullet {
				background: theme('colors.day.04dp');
				height: 0.75rem;
				width: 0.75rem;
				opacity: 1;
				margin: 0 4px;

				&-active {
					background: theme('colors.primary.brand');
				}
			}
		}
	}
}
.mode-dark .confirmation-slider {

	.slider-container .slider-pagination .slider-pagination-bullet {
		background-color: theme('colors.night.on-surface');

		&-active {
			background-color: theme('colors.primary.brand');
		}
	}
}
</style>
