<template>
    <!-- eslint-disable max-len -->
    <div class="ErrorMessage px-4 py-8 rounded-2xl relative overflow-hidden bg-secondary-error">
        <h1 class="text-white" v-html="$t('order.payment-failed-title')"></h1>
        <p class="text-night-on-surface-medium-emphasis mt-2 mb-4">{{ $t('order.payment-failed-description') }}</p>

        <AppButton v-if="buttonLabel" @click="$emit('button-click')" type="white" size="small" width="auto" class="text-secondary-error text-base leading-6 font-medium mx-auto py-2 px-6">
            {{ buttonLabel }}
        </AppButton>

        <!-- <span class="block uppercase text-night-on-surface-low-emphasis-disabled text-xs mt-2">Error-code: 124-payment-canceled</span> -->

        <img src="@/assets/images/error-person.png" class="ErrorMessage_Image absolute" />
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';

export default {
    name: 'ErrorMessage',

    props: {
        title: String,
        description: String,
        buttonLabel: String,
    },

    components: {
        AppButton,
    },
};
</script>

<style lang="scss" scoped>
.ErrorMessage {

    &_Image {
        bottom: -32px;
        right: -32px;
        transform: rotate(340deg);
    }
}
</style>
