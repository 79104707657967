<template>
    <!-- eslint-disable max-len -->
    <div class="machine-flows-junea-OrderJunea bg-day-01dp dark:bg-night-01dp h-full flex flex-col">
        <MachineStatusBar />
        <TheOrderHeader />

        <main class="machine-flows-junea-OrderJunea_Content flex-grow -mt-5 rounded-t-3xl p-5 h-full overflow-y-auto">
            <div :class="['flex justify-between items-center', { 'pb-6': !machine.has_free_vending }]">
                <TapAndGoSwitch />

                <!-- <button type="button" @click="toggleViewMode" class="flex items-center">
                    <AppIcon icon-name="grid" :class="currentViewMode === 'grid' ? 'text-primary-brand' : 'text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'" />
                    <AppIcon icon-name="list" class="ml-2" :class="currentViewMode === 'list' ? 'text-primary-brand' : 'text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis'" />
                </button> -->

                <!-- <button @click="toggleFiltersDrawer" class="flex items-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
                    <AppIcon icon-name="sliders" />
                    <span class="ml-1">{{ $t('order.filters') }}</span>
                </button> -->
            </div>

            <Transition name="wallet-card-height">
                <div v-if="tapAndGoEnabled && !machine.has_free_vending" class="overflow-hidden rounded-2xl">
                    <WalletBalanceCard />
                </div>
            </Transition>

            <Transition name="wallet-card-height">
								<div v-if="tapAndGoEnabled && !machine.has_free_vending" class="h-6"></div>
            </Transition>

            <div class="machine-flows-junea-OrderJunea_Products gap-2 grid" :class="{'grid-cols-2': currentViewMode === 'grid' }">
                <template v-if="currentViewMode === 'grid'">
                    <OrderProductGridItem
                        v-for="(slot, index) in uniqueFilledSlots"
                        :key="`product-${index}-${slot.product.id}-${slot.stock}`"
                        :slot-data="slot" />
                </template>

                <template v-if="currentViewMode === 'list'">
                    <OrderProductListItem
                        v-for="(slot, index) in uniqueFilledSlots"
                        :key="`product-${index}-${slot.product.id}-${slot.stock}`"
                        :slot-data="slot"
                        :product="slot.product" />
                </template>
            </div>
        </main>

        <TheBottomMenu />

        <portal to="drawers">
            <OrderProductFilterDrawer v-if="filtersDrawerActive" @close="toggleFiltersDrawer" />
            <OrderOverviewDrawer v-if="orderOverviewDrawerActive" @close="toggleOrderOverviewDrawer" />

            <CheckoutDrawer
                v-if="checkoutDrawerActive"
                :amount="appliedCoupon ? totalPriceWithDiscount : totalPrice"
                @payment-method-picked="paymentMethodPicked($event)"
                @ideal-bank-selected="idealBankSelected($event)"
                @cancel="togglePaymentMethodDrawer" />
        </portal>

        <portal to="dialogs">
            <NoConnectionDialog v-if="!isConnected" />
        </portal>
    </div>
</template>

<script>
import MachineStatusBar from '@/components/Machine/StatusBar.vue';
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import TapAndGoSwitch from '@/components/TapAndGo/TapAndGoSwitch.vue';
import WalletBalanceCard from '@/components/TapAndGo/WalletBalanceCard.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import OrderProductGridItem from '@/components/Order/ProductGridItem.vue';
import OrderProductListItem from '@/components/Order/ProductListItem.vue';
import OrderProductFilterDrawer from '@/components/Order/ProductFilterDrawer.vue';
import OrderOverviewDrawer from '@/components/Order/OverviewDrawer.vue';
import CheckoutDrawer from '@/components/Drawers/Checkout.vue';
import NoConnectionDialog from '@/components/Dialogs/NoConnection.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import { ref } from '@vue/composition-api';
import useOrder from '@/composables/useOrder';
import useMachine from '@/composables/useMachine';
import useConnection from '@/composables/useConnection';
import useTapAndGo from '@/composables/useTapAndGo';
import useAuth from '@/composables/useAuth';

/**
 * View switch mode module
 * @returns {Object} State and methods
 */
const useViewModeSwitch = () => {
    const currentViewMode = ref('grid');

    /**
     * Method to toggle the view mode between list and grid
     * @returns {void}
     */
    const toggleViewMode = () => {
        currentViewMode.value = currentViewMode.value === 'grid' ? 'list' : 'grid';
    };

    return {
        currentViewMode,
        toggleViewMode,
    };
};

/**
 * Product filter module
 * @returns {Object} State and methods
 */
const useFilters = () => {
    const filtersDrawerActive = ref(false);

    /**
     * Method to toggle the product filter drawer
     * @returns {void}
     */
    const toggleFiltersDrawer = () => {
        filtersDrawerActive.value = !filtersDrawerActive.value;
    };

    return {
        filtersDrawerActive,
        toggleFiltersDrawer,
    };
};

export default {
    name: 'OrderJunea',

    components: {
        MachineStatusBar,
        TheOrderHeader,
        TapAndGoSwitch,
        WalletBalanceCard,
        TheBottomMenu,
        OrderProductGridItem,
        OrderProductListItem,
        OrderProductFilterDrawer,
        OrderOverviewDrawer,
        CheckoutDrawer,
        NoConnectionDialog,
        AppIcon,
    },

    setup() {
        const { uniqueFilledSlots, machine } = useMachine();
        const {
            orderOverviewDrawerActive,
            toggleOrderOverviewDrawer,
            totalPrice,
            appliedCoupon,
            totalPriceWithDiscount,
            checkoutDrawerActive,
            togglePaymentMethodDrawer,
            paymentMethodPicked,
            bankSelectDrawerActive,
            toggleBankSelectDrawer,
            idealBankSelected,
        } = useOrder();

        const { isConnected } = useConnection();
        const { loggedIn, user } = useAuth();
        const { enabled: tapAndGoEnabled } = useTapAndGo();

        // Auto enable Tap&Go if user is logged in and has balance
        if (loggedIn.value && user.value.wallet && user.value.wallet > 0) {
            tapAndGoEnabled.value = true;
        }

        return {
            ...useViewModeSwitch(),
            ...useFilters(),
            orderOverviewDrawerActive,
            toggleOrderOverviewDrawer,
            totalPrice,
            appliedCoupon,
            totalPriceWithDiscount,
            checkoutDrawerActive,
            togglePaymentMethodDrawer,
            paymentMethodPicked,
            bankSelectDrawerActive,
            toggleBankSelectDrawer,
            uniqueFilledSlots,
            idealBankSelected,
            isConnected,
            tapAndGoEnabled,
            machine,
        };
    },
};
</script>

<style lang="scss" scoped>
.machine-flows-junea-OrderJunea {

    &_Content {
        background-color: #f2f2f2;
        margin-bottom: 70px; // Dock menu + toolbar

        .grid {
            grid-auto-rows: 1fr;
        }
    }

    .wallet-card-height-enter-active,
    .wallet-card-height-leave-active {
        transition: all 0.4s;
        max-height: 80px;
    }

    .wallet-card-height-enter,
    .wallet-card-height-leave-to {
        // opacity: 0;
        max-height: 0px;
    }
}

.mode-dark .machine-flows-junea-OrderJunea_Content {
    background: theme('colors.night.01dp')
}
</style>
