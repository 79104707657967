<template>
  <!-- eslint-disable max-len -->
  <div class="order-Cart bg-day-01dp dark:bg-night-01dp h-full flex flex-col">
    <MachineStatusBar v-if="machine.type === 'vending'" />
    <TheOrderHeader />

    <main
      class="flex-grow -mt-5 rounded-t-3xl p-5 bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto flex flex-col justify-between"
    >
      <div>
        <h1 class="flex-1 text-center">{{ $t("order.order-overview.my-order") }}</h1>

        <div class="OrderOverviewDrawer_Cart mt-4 grid row-gap-4">
          <OrderCartItem
            v-for="cartItem in cart"
            :key="`cart-item-${cartItem.slot}`"
            :cart-item="cartItem"
          />
        </div>

        <div class="flex justify-center py-4">
          <RouterLink
            :to="{ name: machine.type === 'fridge' ? 'OrderFridge' : 'OrderJunea' }"
            type="secondary">

            <span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis text-center underline cursor-pointer">
							{{ $t("fridge.add-products-label") }}
						</span>
          </RouterLink>
        </div>
      </div>

      <div v-if="!cart || cart.length == 0" class="text-center my-3">
        <span
          class="font-medium text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"
          >{{ $t("order.order-overview.cart-empty") }}</span
        >
      </div>

      <footer class="pb-20">
				<div>
					<div v-if="totalDeposit > 0" class="flex justify-between items-center">
						<span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t("order.order-overview.deposit") }}</span>
						<span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ currency(totalDeposit) }}</span>
					</div>

					<div class="flex justify-between items-center">
						<span class="text-base leading-6 font-medium">{{ $t("order.order-overview.total-price") }}</span>
						<span class="text-base leading-6 font-medium">{{ currency(appliedCoupon ? totalPriceWithDiscount : totalPrice) }}</span>
					</div>
				</div>

        <div class="OrderOverviewDrawer_Actions mt-4 grid row-gap-2">
          <AppButton
            @click="togglePaymentMethodDrawer"
            type="primary"
            :disabled="!cart || cart.length == 0"
            :class="{ 'opacity-50': !cart || cart.length == 0 }">

            <span v-if="['vending', 'drinks'].includes(machine.type)">
							{{ $t("order.order-overview.button-payment") }} — {{ currency(appliedCoupon ? totalPriceWithDiscount : totalPrice) }}
						</span>

            <span v-if="machine.type === 'fridge' && !isFridgeExtraSession">
							{{ $t("fridge.button-payment") }} — {{ currency(appliedCoupon ? totalPriceWithDiscount : totalPrice) }}
						</span>

            <span v-if="machine.type === 'fridge' && isFridgeExtraSession">
							{{ $t("fridge.button-payment-extra") }} — {{ currency(appliedCoupon ? totalPriceWithDiscount : totalPrice) }}
						</span>
          </AppButton>

          <RouterLink
            :to="{ name: machine.type === 'fridge' ? 'OrderFridge' : 'OrderJunea' }"
            type="secondary">

            <AppButton
              type="secondary"
              icon="arrow-left"
              icon-position="left"
              width="full">

							{{ $t("order.order-overview.button-continue-shopping") }}
						</AppButton>
          </RouterLink>
        </div>
      </footer>
    </main>

    <TheBottomMenu />

    <portal to="drawers">
      <!-- <OrderProductFilterDrawer v-if="filtersDrawerActive" @close="toggleFiltersDrawer" /> -->

      <ProductDrawer
        :unique-slots="uniqueFilledSlots"
        v-if="productDrawerActive"
        @close="toggleProductDrawer"
        :allow-add-to-cart="false"
      />

      <CheckoutDrawer
        v-if="checkoutDrawerActive"
        :amount="appliedCoupon ? totalPriceWithDiscount : totalPrice"
        @payment-method-picked="paymentMethodPicked($event)"
        @ideal-bank-selected="idealBankSelected($event)"
        @cancel="togglePaymentMethodDrawer"
      />
    </portal>
  </div>
</template>

<script>
import MachineStatusBar from "@/components/Machine/StatusBar.vue";
import TheOrderHeader from "@/components/Global/TheOrderHeader.vue";
import TheBottomMenu from "@/components/Global/TheBottomMenu.vue";
import OrderCartItem from "@/components/Order/CartItem.vue";
import CheckoutDrawer from "@/components/Drawers/Checkout.vue";
// import OrderCouponCode from '@/components/Order/CouponCode.vue';
import AppButton from "@/components/Interface/AppButton.vue";
import useOrder from "@/composables/useOrder";
import useAuth from "@/composables/useAuth";
import useFilters from "@/filters";
import useMachine from "@/composables/useMachine";
import ProductDrawer from "@/components/Fridge/ProductDrawer.vue";
import { ref } from "@vue/composition-api";

export default {
  name: "OrderCart",

  components: {
    MachineStatusBar,
    TheOrderHeader,
    TheBottomMenu,
    OrderCartItem,
    CheckoutDrawer,
    // OrderCouponCode,
    AppButton,
    ProductDrawer,
  },

  setup() {
    const {
      cart,
      totalPrice,
      togglePaymentMethodDrawer,
      checkoutDrawerActive,
      paymentMethodPicked,
      bankSelectDrawerActive,
      toggleBankSelectDrawer,
      appliedCoupon,
      totalPriceWithDiscount,
      idealBankSelected,
      isFridgeExtraSession,
			totalDeposit,
    } = useOrder();

    const { currency } = useFilters();
    const { loggedIn } = useAuth();
    const { machine, uniqueFilledSlots } = useMachine();

    const productDrawerActive = ref(false);
    function toggleProductDrawer() {
      productDrawerActive.value = !productDrawerActive.value;
    }

    return {
      cart,
      totalPrice,
      togglePaymentMethodDrawer,
      checkoutDrawerActive,
      paymentMethodPicked,
      bankSelectDrawerActive,
      toggleBankSelectDrawer,
      currency,
      appliedCoupon,
      totalPriceWithDiscount,
      idealBankSelected,
      loggedIn,
      machine,
      productDrawerActive,
      toggleProductDrawer,
      uniqueFilledSlots,
      isFridgeExtraSession,
			totalDeposit,
    };
  },
};
</script>
