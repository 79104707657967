<template>
	<div class="OrderCartItem bg-day-04dp dark:bg-night-16dp rounded-lg py-3 px-4 flex items-center justify-between">
			<div class="OrderCartItem_Left flex items-center flex-1">
					<div class="bg-white rounded py-4 w-14 h-14 relative">
							<img :src="product.image" :alt="product.name" class="absolute inset-0 mx-auto rounded h-full object-cover" />
					</div>

					<div class="ml-2">
							<span class="block font-medium">{{ product.name }}</span>

							<div class="inline-flex space-x-1">
								<span class="block font-bold text-day-on-surface-ultra-emphasis dark:text-night-on-surface-ultra-emphasis">
									{{ currency(cartItemSlot.price) }}
								</span>

								<span v-if="cartItemSlot.deposit && cartItemSlot.deposit > 0" class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled text-xs">
									{{ $t('order.excl-deposit') }}
								</span>
							</div>
					</div>
			</div>

			<div v-if="allowAmountSelect" class="OrderCartItem_Right ml-2">
					<AppAmountSelector
							:amount="cartItem.amount"
							:max="cartItemSlot.stock"
							@increase="increaseAmount"
							@decrease="decreaseAmount" />
			</div>
	</div>
</template>

<script>
import AppAmountSelector from '@/components/Interface/AppAmountSelector.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import useFilters from '@/filters';

export default {
    name: 'OrderCartItem',

    props: {
        cartItem: {
            type: Object,
            required: true,
        },
        touch: {
            type: Boolean,
            default: false,
        },
        allowAmountSelect: {
            type: Boolean,
            default: true,
        },
    },

    components: {
        AppAmountSelector,
    },

    setup(props, { root }) {
        const { uniqueFilledSlots } = useMachine();
        const { cart, addProductToCart, removeProductFromCart } = useOrder();
        const { currency } = useFilters();

        /**
         * Property to find the current cart item slot object
         * @returns {Object} Slot object with product inside
         */
        const cartItemSlot = uniqueFilledSlots.value.find(
            (slot) => slot.slot === props.cartItem.slot,
        );

        /**
         * Method to increase the amount of the current cart item
         * @returns {void}
         */
        const increaseAmount = () => {
            if (props.cartItem.amount < cartItemSlot.stock) {
                addProductToCart(props.cartItem.slot, props.cartItem.product, props.touch);
            } else {
                root.$toast.open({
                    message: root.$t('order.out-of-stock-notification'),
                    type: 'warning',
                });
            }
        };

        /**
         * Method to increase the amount of the current cart item
         * @returns {void}
         */
        const decreaseAmount = () => {
            const cartItem = cart.value.find(
                (item) => item.slot === props.cartItem.slot,
            );

            // If decrease to 0, remove item from cart
            if (cartItem.amount === 1) {
                removeProductFromCart(cartItem.slot);
            }

            // If amount is greater than 1, decrease amount
            if (cartItem.amount > 1) {
                cartItem.amount -= 1;
            }
        };

        return {
            currency,
            cartItemSlot,
            product: cartItemSlot.product,
            increaseAmount,
            decreaseAmount,
        };
    },
};
</script>

