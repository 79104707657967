<template>
    <!-- eslint-disable max-len -->
    <div class="FridgeProcessingPayment flex flex-col overflow-y-auto bg-day-01dp dark:bg-night-01dp">
        <TheOrderHeader machine-type="fridge" />

        <main v-if="fridgeDoorStatus === 'unset'" class="FridgeProcessingPayment_Content flex-grow flex flex-col flex-shrink-0 justify-center px-4 -mt-5">
            <div class="mb-4 rounded-2xl text-center" :class="paymentError ? '' : 'p-4 bg-day-04dp dark:bg-night-08dp'">
                <template v-if="!fetchingPaymentStatus && !paymentError">
                    <h1 v-if="$route.query.type === 'junea'" class="mb-2" v-html="$t('order.order-overview.success-heading')"></h1>
                    <h1 v-if="$route.query.type === 'wallet'" class="mb-2" v-html="$t('order.order-overview.success-heading-wallet')"></h1>

                    <p v-if="!paymentStartedInCurrentWindow" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('order.order-overview.return-to-app') }}</p>

                    <div v-if="machine" class="FridgeProcessingPayment_Buttons flex justify-center mt-4">
                        <AppButton @click="startNewFridgeSession" size="small" icon="arrow-right" icon-position="right">
                            {{ $route.query.type === 'wallet' ? $t('order.order-overview.wallet-start-shopping') : $t('order.order-overview.continue-shopping') }}
                        </AppButton>
                    </div>
                </template>

                <!-- Payment pending -->
                <div v-if="fetchingPaymentStatus" class="FridgeProcessingPayment_Pending flex items-center justify-center">
                    <svg class="spinner" viewBox="0 0 100 100" width="20" height="20">
                        <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
                    </svg>

                    <h2 class="ml-2" v-html="$t('order.order-overview.fetching-payment-status')"></h2>
                </div>

                 <!-- Payment failed -->
                 <ErrorMessage
                    v-if="paymentError && machine"
                    :title="$t('order.payment-failed-title')"
                    :description="$t('order.payment-failed-description')"
                    button-label="Opnieuw proberen"
                    @button-click="$router.push({ name: machine.type === 'fridge' ? 'OrderFridge' : 'OrderJunea' })" />
            </div>

            <QuoteImageBlock v-if="!paymentError" />
            <span v-if="paymentError" class="flex-grow"><!-- Spacer --></span>
        </main>

        <!-- Fridge loading screen -->
        <div v-else class="FridgeProcessingPayment_Content flex-grow flex flex-col flex-shrink-0 rounded-t-xl -mt-5" :style="{background: fridgeDoorStatus === 'unlocked' ? '#27AE60' : '#F39C12'}">

            <!-- Door unlocked -->
            <header class="FridgeProcessingPayment_DoorStatus flex items-center justify-center py-3 px-4 rounded-t-xl">
                <template v-if="fridgeDoorStatus === 'unlocked'">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 8.99992H9.5V6.99992C9.49854 6.40611 9.67334 5.82522 10.0023 5.33082C10.3312 4.83643 10.7994 4.45077 11.3477 4.22268C11.8959 3.99459 12.4996 3.93435 13.0821 4.04957C13.6646 4.16479 14.1999 4.45029 14.62 4.86992C14.9959 5.25399 15.2649 5.72975 15.4 6.24992C15.4328 6.3773 15.4904 6.49697 15.5695 6.60209C15.6486 6.70721 15.7476 6.79573 15.8609 6.86259C15.9742 6.92945 16.0995 6.97334 16.2298 6.99175C16.36 7.01017 16.4926 7.00275 16.62 6.96992C16.7474 6.93709 16.8671 6.87949 16.9722 6.80041C17.0773 6.72133 17.1658 6.62232 17.2327 6.50904C17.2995 6.39575 17.3434 6.2704 17.3618 6.14015C17.3802 6.0099 17.3728 5.8773 17.34 5.74992C17.1122 4.88472 16.6603 4.09486 16.03 3.45992C15.3302 2.76229 14.4393 2.28766 13.47 2.09596C12.5006 1.90427 11.4961 2.00411 10.5835 2.38288C9.67078 2.76164 8.8908 3.40235 8.34201 4.22409C7.79321 5.04584 7.50021 6.01177 7.5 6.99992V8.99992C6.70435 8.99992 5.94129 9.31599 5.37868 9.8786C4.81607 10.4412 4.5 11.2043 4.5 11.9999V18.9999C4.5 19.7956 4.81607 20.5586 5.37868 21.1212C5.94129 21.6838 6.70435 21.9999 7.5 21.9999H17.5C18.2956 21.9999 19.0587 21.6838 19.6213 21.1212C20.1839 20.5586 20.5 19.7956 20.5 18.9999V11.9999C20.5 11.2043 20.1839 10.4412 19.6213 9.8786C19.0587 9.31599 18.2956 8.99992 17.5 8.99992ZM18.5 18.9999C18.5 19.2651 18.3946 19.5195 18.2071 19.707C18.0196 19.8946 17.7652 19.9999 17.5 19.9999H7.5C7.23478 19.9999 6.98043 19.8946 6.79289 19.707C6.60536 19.5195 6.5 19.2651 6.5 18.9999V11.9999C6.5 11.7347 6.60536 11.4803 6.79289 11.2928C6.98043 11.1053 7.23478 10.9999 7.5 10.9999H17.5C17.7652 10.9999 18.0196 11.1053 18.2071 11.2928C18.3946 11.4803 18.5 11.7347 18.5 11.9999V18.9999Z" fill="white"/>
                        <path d="M11.7929 13.2929C11.9804 13.1054 12.2348 13 12.5 13C12.7652 13 13.0196 13.1054 13.2071 13.2929C13.3946 13.4804 13.5 13.7348 13.5 14V17C13.5 17.2652 13.3946 17.5196 13.2071 17.7071C13.0196 17.8946 12.7652 18 12.5 18C12.2348 18 11.9804 17.8946 11.7929 17.7071C11.6054 17.5196 11.5 17.2652 11.5 17V14C11.5 13.7348 11.6054 13.4804 11.7929 13.2929Z" fill="white"/>
                    </svg>

                    <span class="text-white">{{ $t('fridge.door-unlocked') }}</span>
                </template>

                <template v-if="fridgeDoorStatus === 'locked'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 9V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7V9C6.20435 9 5.44129 9.31607 4.87868 9.87868C4.31607 10.4413 4 11.2044 4 12V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V12C20 11.2044 19.6839 10.4413 19.1213 9.87868C18.5587 9.31607 17.7956 9 17 9ZM9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V9H9V7ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12V19Z" fill="white"/>
                        <path d="M11.2929 13.2929C11.4804 13.1054 11.7348 13 12 13C12.2652 13 12.5196 13.1054 12.7071 13.2929C12.8946 13.4804 13 13.7348 13 14V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V14C11 13.7348 11.1054 13.4804 11.2929 13.2929Z" fill="white"/>
                    </svg>

                    <span class="text-white">{{ $t('fridge.door-locked') }}</span>
                </template>
            </header>

            <div class="px-4 mt-8 h-full flex flex-col justify-between">
                <header class="text-center grid gap-2 mb-6">
                    <h1 class="mb-6 text-white">{{ $t('fridge.ready-door-open') }}</h1>

                    <FrigdeConfirmationProduct
                        v-for="orderItem in orderItems"
                        :key="`order-item-${orderItem.reference}`"
                        :order-item="orderItem" />
                </header>

                <footer class="button-group grid gap-2">
                    <button @click="finishPurchase" class="rounded-full block w-full appearance-none py-3 px-4 bg-white text-night-01dp font-medium">{{ $t('fridge.done') }}</button>

                    <div class="grid grid-cols-2 gap-2">
                        <button @click="startNewFridgeSession('extra')" class="rounded-4xl flex flex-col justify-center items-center space-x-1 w-full appearance-none py-4 px-2 border-1-5 border-white text-white font-medium">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.60031 10.6497H10.8997V3.35031C10.8997 2.99218 11.042 2.64873 11.2952 2.3955C11.5484 2.14226 11.8919 2 12.25 2C12.6081 2 12.9516 2.14226 13.2048 2.3955C13.458 2.64873 13.6003 2.99218 13.6003 3.35031V10.6497H20.8997C21.2578 10.6497 21.6013 10.792 21.8545 11.0452C22.1077 11.2984 22.25 11.6419 22.25 12C22.25 12.3581 22.1077 12.7016 21.8545 12.9548C21.6013 13.208 21.2578 13.3503 20.8997 13.3503H13.6003V20.6497C13.6003 21.0078 13.458 21.3513 13.2048 21.6045C12.9516 21.8577 12.6081 22 12.25 22C11.8919 22 11.5484 21.8577 11.2952 21.6045C11.042 21.3513 10.8997 21.0078 10.8997 20.6497V13.3503H3.60031C3.24218 13.3503 2.89873 13.208 2.64549 12.9548C2.39226 12.7016 2.25 12.3581 2.25 12C2.25 11.6419 2.39226 11.2984 2.64549 11.0452C2.89873 10.792 3.24218 10.6497 3.60031 10.6497Z" fill="white"/>
                            </svg>

                            {{ $t('fridge.took-extra') }}
                        </button>

                        <button @click="openDoor" :class="{ 'pointer-events-none cursor-not-allowed opacity-25': fridgeDoorStatus !== 'locked' }" class="rounded-4xl flex flex-col justify-center items-center space-x-1 w-full appearance-none py-4 px-2 border-1-5 border-white text-white font-medium">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.25 8.99992H15.25V6.99992C15.2515 6.40611 15.0767 5.82522 14.7477 5.33082C14.4188 4.83643 13.9506 4.45077 13.4023 4.22268C12.8541 3.99459 12.2504 3.93435 11.6679 4.04957C11.0854 4.16479 10.5501 4.45029 10.13 4.86992C9.75405 5.25399 9.48515 5.72975 9.35 6.24992C9.31717 6.3773 9.25957 6.49697 9.18049 6.60209C9.10141 6.70721 9.00241 6.79573 8.88912 6.86259C8.77583 6.92945 8.65048 6.97334 8.52023 6.99175C8.38998 7.01017 8.25738 7.00275 8.13 6.96992C8.00262 6.93709 7.88295 6.87949 7.77783 6.80041C7.6727 6.72133 7.58419 6.62232 7.51733 6.50904C7.45047 6.39575 7.40658 6.2704 7.38817 6.14015C7.36975 6.0099 7.37717 5.8773 7.41 5.74992C7.63782 4.88472 8.08966 4.09486 8.72 3.45992C9.41982 2.76229 10.3107 2.28766 11.28 2.09596C12.2494 1.90427 13.2539 2.00411 14.1665 2.38288C15.0792 2.76164 15.8592 3.40235 16.408 4.22409C16.9568 5.04584 17.2498 6.01177 17.25 6.99992V8.99992C18.0456 8.99992 18.8087 9.31599 19.3713 9.8786C19.9339 10.4412 20.25 11.2043 20.25 11.9999V18.9999C20.25 19.7956 19.9339 20.5586 19.3713 21.1212C18.8087 21.6838 18.0456 21.9999 17.25 21.9999H7.25C6.45435 21.9999 5.69129 21.6838 5.12868 21.1212C4.56607 20.5586 4.25 19.7956 4.25 18.9999V11.9999C4.25 11.2043 4.56607 10.4412 5.12868 9.8786C5.69129 9.31599 6.45435 8.99992 7.25 8.99992ZM6.25 18.9999C6.25 19.2651 6.35536 19.5195 6.54289 19.707C6.73043 19.8946 6.98478 19.9999 7.25 19.9999H17.25C17.5152 19.9999 17.7696 19.8946 17.9571 19.707C18.1446 19.5195 18.25 19.2651 18.25 18.9999V11.9999C18.25 11.7347 18.1446 11.4803 17.9571 11.2928C17.7696 11.1053 17.5152 10.9999 17.25 10.9999H7.25C6.98478 10.9999 6.73043 11.1053 6.54289 11.2928C6.35536 11.4803 6.25 11.7347 6.25 11.9999V18.9999Z" fill="white"/>
                            </svg>

                            {{ $t('fridge.unlock-door') }}
                        </button>
                    </div>
                </footer>
            </div>
        </div>

        <TheBottomMenu v-if="paymentStartedInCurrentWindow" />

         <portal to="drawers">
            <AuthLoginDrawer
                v-if="loginDrawerActive"
                @close="toggleLoginDrawer"
                @show-register="toggleRegisterDrawer"
                @forgot-password="toggleResetPasswordDrawer" />

            <AuthCreateAccountDrawer
                v-if="registerDrawerActive"
                @close="toggleRegisterDrawer"
                @show-login="toggleLoginDrawer" />

            <AuthResetPasswordDrawer
                v-if="resetPasswordDrawerActive"
                @close="toggleResetPasswordDrawer"
                @show-login="toggleLoginDrawer" />

            <AddToHomescreenNotfication v-if="fridgeDoorStatus === 'unset'" />
        </portal>
    </div>
</template>

<script>
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AuthLoginDrawer from '@/components/Auth/LoginDrawer.vue';
import AuthCreateAccountDrawer from '@/components/Auth/CreateAccountDrawer.vue';
import AuthResetPasswordDrawer from '@/components/Auth/ResetPasswordDrawer.vue';
import QuoteImageBlock from '@/components/QuoteImageBlock.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import { onMounted, ref } from '@vue/composition-api';
import useAuth from '@/composables/useAuth';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import AddToHomescreenNotfication from '@/components/AddToHomescreenNotfication.vue';
import FrigdeConfirmationProduct from '@/components/Fridge/ConfirmationProduct.vue';

export default {
    name: 'FridgeOrderProcessingPayment',

    components: {
        TheOrderHeader,
        AppButton,
        AuthLoginDrawer,
        AuthCreateAccountDrawer,
        AuthResetPasswordDrawer,
        QuoteImageBlock,
        ErrorMessage,
        TheBottomMenu,
        AddToHomescreenNotfication,
        FrigdeConfirmationProduct,
    },

    setup(_, { root }) {
        const { loggedIn } = useAuth();
        const { machine, fetchMachineData } = useMachine();
        const { fridgeSessionId, isFridgeExtraSession } = useOrder();

        const loginDrawerActive = ref(false);
        const registerDrawerActive = ref(false);
        const resetPasswordDrawerActive = ref(false);

        const orderItems = ref([]);

        const fridgeDoorStatus = ref('unset');

        /* eslint-disable no-unused-vars */
        let fridgeTimeout = null;

        /**
         * Method to toggle the login drawer
         * @returns {void}
         */
        const toggleLoginDrawer = () => {
            registerDrawerActive.value = false;
            resetPasswordDrawerActive.value = false;
            loginDrawerActive.value = !loginDrawerActive.value;
        };

        /**
         * Method to toggle the register drawer
         * @returns {void}
         */
        const toggleRegisterDrawer = () => {
            loginDrawerActive.value = false;
            registerDrawerActive.value = !registerDrawerActive.value;
        };

        /**
         * Method to toggle the password reset drawer
         * @returns {void}
         */
        const toggleResetPasswordDrawer = () => {
            loginDrawerActive.value = false;
            resetPasswordDrawerActive.value = !resetPasswordDrawerActive.value;
        };

        /**
         * Method to fetch payment status
         */
        const fetchingPaymentStatus = ref(false);
        const paymentError = ref(null);

        async function checkPaymentStatus(paymentOrderReference) {
            fetchingPaymentStatus.value = true;

            try {
                const paymentStatusResponse = await root.$http.get(`/payment-status/${paymentOrderReference}`);
                const paymentStatus = paymentStatusResponse.data.payment_status;
                orderItems.value = paymentStatusResponse.data.data.products;
                fridgeSessionId.value = paymentStatusResponse.data.data.session_id;

                if (!machine.value) {
                    fetchMachineData(paymentStatusResponse.data.data.machine);
                }

                if (paymentStatusResponse.data.data.vending_session_status === 'completed') {
                    fridgeDoorStatus.value = 'unset';
                    fetchingPaymentStatus.value = false;
                    return;
                }

                // Payment paid: show success message
                if (paymentStatus === 'paid') {
                    fetchingPaymentStatus.value = false;

                    // Remove cart from local storage after successful payment
                    localStorage.removeItem('order-cart');

                    if (paymentStatusResponse.data.data.order_amount === 2) {
                        fridgeDoorStatus.value = 'unset';
                        fridgeSessionId.value = null;
                        isFridgeExtraSession.value = false;
                    } else {
                        fridgeDoorStatus.value = 'unlocked';

                        // After 15 seconds the door will be locked
                        fridgeTimeout = setTimeout(() => {
                            fridgeDoorStatus.value = 'locked';

                            // After another 30 seconds order is finished
                            fridgeTimeout = setTimeout(() => {
                                fridgeDoorStatus.value = 'unset';
                                fridgeSessionId.value = null;
                                isFridgeExtraSession.value = false;
                            }, 30000);
                        }, 15000);
                    }
                }

                // Payment canceled: show error
                if (paymentStatus === 'canceled') {
                    paymentError.value = 'payment_canceled';

                    fetchingPaymentStatus.value = false;
                    // localStorage.removeItem('order-id');
                }

                // Still pending, try again in 5 seconds
                if (paymentStatus === 'init') {
                    throw new Error('payment_pending');
                }
            } catch (error) {
                setTimeout(() => {
                    checkPaymentStatus(paymentOrderReference);
                }, 5000);
            }
        }

        const paymentStartedInCurrentWindow = ref(false);

        onMounted(() => {
            const { reference } = root.$router.currentRoute.query;

            if (localStorage.getItem('processing-payment')) {
                paymentStartedInCurrentWindow.value = true;

                localStorage.removeItem('processing-payment');
            }

            checkPaymentStatus(reference);
        });

        async function startNewFridgeSession(extra) {
            if (extra === 'extra') {
                isFridgeExtraSession.value = true;
            } else {
                fridgeSessionId.value = null;
                isFridgeExtraSession.value = false;
            }

            root.$router.push({ name: 'OrderFridge' });
        }

        async function openDoor() {
            try {
                await root.$http.post(`/fridge/session/${fridgeSessionId.value}/door-open`);
                clearTimeout(fridgeTimeout);
                fridgeDoorStatus.value = 'unlocked';

                // After 15 seconds the door will be locked
                fridgeTimeout = setTimeout(() => {
                    fridgeDoorStatus.value = 'locked';

                    // After another 30 seconds order is finished
                    fridgeTimeout = setTimeout(() => {
                        fridgeDoorStatus.value = 'unset';
                        fridgeSessionId.value = null;
                        isFridgeExtraSession.value = false;
                    }, 30000);
                }, 15000);
            } catch (error) {
                console.log(error);
            }
        }

        async function closeDoor() {
            try {
                await root.$http.post(`/fridge/session/${fridgeSessionId.value}/door-close`);
                clearTimeout(fridgeTimeout);
                fridgeDoorStatus.value = 'locked';
            } catch (error) {
                console.log(error);
            }
        }

        function finishPurchase() {
            fridgeDoorStatus.value = 'unset';
            clearTimeout(fridgeTimeout);
            fridgeSessionId.value = null;
            isFridgeExtraSession.value = false;
        }

        return {
            loginDrawerActive,
            registerDrawerActive,
            resetPasswordDrawerActive,
            toggleLoginDrawer,
            toggleRegisterDrawer,
            toggleResetPasswordDrawer,
            loggedIn,
            fetchingPaymentStatus,
            paymentError,
            paymentStartedInCurrentWindow,
            machine,
            startNewFridgeSession,
            fridgeDoorStatus,
            openDoor,
            closeDoor,
            orderItems,
            finishPurchase,
        };
    },
};
</script>

<style lang="scss">
.FridgeProcessingPayment {
    height: 100%;

    &_Content {
        padding-bottom: 84px; // Dock menu + toolbar
    }

    &_Pending {
        @-webkit-keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        @keyframes spin {
            to { stroke-dashoffset: -264; }
        }

        .spinner circle {
            fill: none;
            stroke: #1a1a1a;
            stroke-width: 16;
            stroke-linecap: round;
            stroke-dasharray: 0, 0, 70, 194;
            stroke-dashoffset: 0;
            animation: spin 1.4s infinite linear;
            -webkit-animation: spin 1.4s infinite linear;
        }
    }

    &_DoorStatus,
    footer.button-group > div button {
        background: rgba(0, 0, 0, 0.1)
    }
}

.mode-dark .FridgeProcessingPayment {

    .layout-TheOrderHeader {
        @apply bg-night-01dp;
    }

    &_Pending {
        .spinner circle {
            stroke: white;
        }
    }
}
</style>
