<template>
    <!-- eslint-disable max-len -->
    <div class="order-FridgeScan bg-day-01dp dark:bg-night-01dp h-full flex flex-col">
        <TheOrderHeader machine-type="fridge" />

        <main class="order-FridgeScan_Content flex-grow -mt-5 rounded-t-3xl p-5 h-full overflow-y-auto flex flex-col justify-between">
            <div class="grid gap-4">
                <h1 v-if="isFridgeExtraSession" class="text-center">{{ $t('fridge.select-extra-products') }}</h1>
                <div class="flex flex-no-wrap overflow-auto space-x-2 pb-4">
                    <div @click="selectedCategory = 'all'" class="rounded-full py-1 px-4"
                        :class="{
                            'bg-primary-brand text-white': selectedCategory === 'all',
                            'bg-day-01dp dark:bg-night-04dp': selectedCategory !== 'all',
                        }"
                        style="min-width: fit-content;">

                        {{ $t('fridge.all-products') }}
                    </div>

                    <div v-for="category in productCategories"
                        :key="`category-${category.reference}`"
                        @click="selectedCategory = category.reference"
                        class="rounded-full py-1 px-4"
                        style="min-width: fit-content;"
                        :class="{
                            'bg-primary-brand text-white': selectedCategory === category.reference,
                            'bg-day-01dp dark:bg-night-04dp': selectedCategory !== category.reference,
                        }">

                        {{ category.name }}
                    </div>
                </div>

                <div class="grid gap-2">
                    <FridgeProductItem
                        v-for="slot in categoryFilteredProducts"
                        :key="slot.id"
                        :slot-data="slot"
                        :product="slot.product" />
                </div>
            </div>

            <RouterLink v-if="totalCartSize > 0" :to="{ name: 'OrderCart' }" class="sticky bottom-0 mt-6">
                <AppButton icon="shopping-cart" width="full">{{ $t('fridge.cart-button') }} — {{ currency(totalPrice) }}</AppButton>
            </RouterLink>
        </main>

        <TheBottomMenu />

        <portal to="drawers">
            <OrderOverviewDrawer
                v-if="orderOverviewDrawerActive"
                @close="toggleOrderOverviewDrawer" />

            <CheckoutDrawer
                v-if="checkoutDrawerActive"
                :amount="totalPrice"
                @payment-method-picked="paymentMethodPicked($event)"
                @ideal-bank-selected="idealBankSelected($event)"
                @cancel="togglePaymentMethodDrawer" />
        </portal>
    </div>
</template>

<script>
/* eslint-disable max-len, arrow-body-style */
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import OrderOverviewDrawer from '@/components/Order/OverviewDrawer.vue';
import CheckoutDrawer from '@/components/Drawers/Checkout.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import FridgeProductItem from '@/components/Fridge/FridgeProductItem.vue';
import useFilters from '@/filters';
import { computed, ref } from '@vue/composition-api';

export default {
    name: 'FridgeScan',

    components: {
        TheOrderHeader,
        TheBottomMenu,
        OrderOverviewDrawer,
        CheckoutDrawer,
        AppButton,
        FridgeProductItem,
    },

    setup() {
        const { uniqueFilledSlots } = useMachine();
        const { currency } = useFilters();

        const {
            orderOverviewDrawerActive,
            toggleOrderOverviewDrawer,
            totalCartSize,
            checkoutDrawerActive,
            togglePaymentMethodDrawer,
            totalPrice,
            paymentMethodPicked,
            idealBankSelected,
            isFridgeExtraSession,
        } = useOrder();

        // Map all categories from slot product into new array
        let productCategories = uniqueFilledSlots.value
            .filter((slot) => slot.product.category)
            .map((slot) => slot.product.category);

        // Filter all unique categories
        productCategories = [...new Map(productCategories.map((item) => [item.reference, item])).values()];

        // Current selected category
        const selectedCategory = ref('all');

        // Filter products based on selected category
        const categoryFilteredProducts = computed(() => {
            // If category all is selected, return all slots
            if (selectedCategory.value === 'all') {
                return uniqueFilledSlots.value;
            }

            return uniqueFilledSlots.value.filter((slot) => slot.product.category?.reference === selectedCategory.value);
        });

        return {
            uniqueFilledSlots,
            totalCartSize,
            checkoutDrawerActive,
            togglePaymentMethodDrawer,
            totalPrice,
            paymentMethodPicked,
            idealBankSelected,
            orderOverviewDrawerActive,
            toggleOrderOverviewDrawer,
            currency,
            productCategories,
            selectedCategory,
            categoryFilteredProducts,
            isFridgeExtraSession,
        };
    },
};
</script>

<style lang="scss">
.order-FridgeScan {

    &_Content {
        background-color: #f2f2f2;
        margin-bottom: 70px; // Dock menu + toolbar

        // .grid {
        //     grid-auto-rows: 1fr;
        // }
    }
}

.mode-dark .order-FridgeScan_Content {
    background: theme('colors.night.01dp')
}
</style>
