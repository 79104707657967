<template>
    <!-- eslint-disable max-len -->
    <div class="FrigdeConfirmationProduct rounded-2xl py-3 px-4 flex items-center justify-between">
        <div class="flex items-center flex-1">
            <div class="bg-white rounded">
                <img :src="orderItem.image" alt="test" class="w-12 h-12 object-contain" />
            </div>

            <div class="ml-2">
                <span class="block font-medium text-left text-white">{{ orderItem.name }}</span>
            </div>
        </div>

        <div class="ml-2 rounded-full w-8 h-8 flex items-center justify-center border-1-5 border-white text-white">
            {{ orderItem.count }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FrigdeConfirmationProduct',

    props: {
        orderItem: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.FrigdeConfirmationProduct {
    background: rgba(0, 0, 0, 0.15);
}
</style>
