<template>
    <!-- eslint-disable max-len -->
    <div @click="allowRefill ? $emit('refill-slot', slotData) : updateInput()" class="OperatorDrinksSlot flex justify-between items-center px-4 py-2 rounded-lg bg-day-06dp dark:bg-night-16dp cursor-pointer"
        :class="{ 'OperatorDrinksSlot-active': modelValue.includes(slotData.id) && !allowRefill, 'opacity-50': loading, 'pointer-events-none': loading || completed }">
        <div class="flex-grow flex items-center">
            <div v-if="!allowRefill" class="w-5 h-5 rounded bg-day-01dp dark:bg-night-01dp flex items-center justify-center mr-3 flex-shrink-0">
                <div v-if="modelValue.includes(slotData.id)" class="block w-3 h-3 bg-primary-brand rounded-sm"></div>
            </div>

            <svg v-if="loading" class="spinner mr-3 flex-shrink-0" viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
            </svg>

            <!-- Completed check -->
            <svg v-if="completed" class="mr-3 flex-shrink-0" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9991 0.474202C16.9026 0.376756 16.7878 0.299411 16.6613 0.246629C16.5348 0.193846 16.3991 0.166672 16.262 0.166672C16.125 0.166672 15.9893 0.193846 15.8628 0.246629C15.7363 0.299411 15.6214 0.376756 15.5249 0.474202L6.75237 9.26974L2.46472 4.96554C2.36451 4.86861 2.24622 4.79238 2.1166 4.74123C1.98698 4.69007 1.84857 4.66499 1.70927 4.6674C1.56996 4.66981 1.4325 4.69968 1.30472 4.75529C1.17695 4.81091 1.06136 4.89118 0.964562 4.99153C0.867764 5.09188 0.791652 5.21034 0.740569 5.34015C0.689487 5.46996 0.664436 5.60857 0.666846 5.74807C0.669256 5.88758 0.69908 6.02524 0.754616 6.1532C0.810151 6.28116 0.89031 6.39691 0.990516 6.49385L6.01527 11.5258C6.11178 11.6233 6.2266 11.7006 6.35312 11.7534C6.47963 11.8062 6.61532 11.8333 6.75237 11.8333C6.88942 11.8333 7.02512 11.8062 7.15163 11.7534C7.27814 11.7006 7.39296 11.6233 7.48947 11.5258L16.9991 2.00251C17.1045 1.90515 17.1886 1.78699 17.2461 1.65547C17.3037 1.52396 17.3334 1.38193 17.3334 1.23835C17.3334 1.09477 17.3037 0.952751 17.2461 0.821235C17.1886 0.689718 17.1045 0.571558 16.9991 0.474202Z" fill="#27AE60"/>
            </svg>

            <!-- Product + next product image -->
            <div v-if="slotData.product" class="OperatorDrinksSlot_Images relative">
                <img v-if="slotData.product.image"
                    :src="slotData.product.image"
                    :class="['object-contain rounded-lg bg-white relative z-10']" />
            </div>

            <!-- Product name + next product name -->
            <div class="ml-4">
                <span class="dark:text-night-on-surface-low-emphasis-disabled mr-2">#{{ slotData.position + 1 }}</span>
                <span v-if="slotData.product" class="block">{{ slotData.product.name }}</span>
            </div>
        </div>

        <div class="w-20 flex-shrink-0">
            <SlotStockIndicator
                :percentage="slotData.stock_percentage ? slotData.stock_percentage : Number((slotData.stock + slotData.next_product_stock) / slotData.capacity * 100)"
                :stock="slotData.next_product_stock ? slotData.stock + slotData.next_product_stock : slotData.stock"
                :capacity="slotData.capacity" />
        </div>
    </div>
</template>

<script>
import SlotStockIndicator from '@/components/Operator/SlotStockIndicator.vue';

export default {
    name: 'DrinksSlot',

    props: {
        slotData: Object,
        allowRefill: Boolean,
        modelValue: { default: '' },
        loading: Boolean,
        completed: Boolean,
    },

    components: {
        SlotStockIndicator,
    },

    model: {
        prop: 'modelValue',
        event: 'change',
    },

    methods: {
        updateInput() {
            const newValue = [...this.modelValue];

            if (!newValue.includes(this.slotData.id)) {
                newValue.push(this.slotData.id);
            } else {
                newValue.splice(newValue.indexOf(this.slotData.id), 1);
            }

            this.$emit('change', newValue);
        },
    },
};
</script>

<style lang="scss">
.OperatorDrinksSlot {

    &-active {
        background: linear-gradient(
            0deg,
            rgba(237, 74, 104, 0.08),
            rgba(237, 74, 104, 0.08)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)
        ), #E4E4E4;

        box-shadow: 0px 0px 0px 2px #ED4A68;
    }

    &_Images {

        img {
            width: 52px;
            height: 52px;
        }
    }

    .spinner circle {
        fill: none;
        stroke: #1a1a1a;
        stroke-width: 16;
        stroke-linecap: round;
        stroke-dasharray: 0, 0, 70, 194;
        stroke-dashoffset: 0;
        animation: spin 1.4s infinite linear;
        -webkit-animation: spin 1.4s infinite linear;
    }
}

.mode-dark .OperatorDrinksSlot {

    &-active {
        background: linear-gradient(
            0deg,
            rgba(237, 74, 104, 0.08),
            rgba(237, 74, 104, 0.08)),
            linear-gradient(0deg, rgba(255, 255, 255, 0.02),
            rgba(255, 255, 255, 0.02));
    }

    .spinner circle {
        stroke: white;
    }
}
</style>
